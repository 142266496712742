import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { ListGroup } from 'Components/ListGroup'
import { EpiContent } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useUserInformation } from 'Utils/UserContext'
import getConfig from 'Utils/config'
import React, { useMemo, useRef, useState } from 'react'

const ProfilePictureAvatar = () => {
  const { user, getUserInitials } = useUserInformation()
  const userInitials = useMemo(() => getUserInitials(), [getUserInitials])
  const { MEDIA_URL } = getConfig()
  const mediaURL = MEDIA_URL ?? ''
  const imageFileName = user.imageFileName ?? ''

  const imageUrl = useMemo(
    () => `${mediaURL}/user-profile-images/${imageFileName}`,
    [mediaURL, imageFileName]
  )
  const avatarSize = imageFileName ? 64 : 40

  if (!user.imageFileName) {
    return null
  }
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Avatar sx={{ width: avatarSize, height: avatarSize }} src={imageUrl}>
        {userInitials}
      </Avatar>
    </Box>
  )
}

const ChangePictureButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleChangeButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const { profilBild } = useEpiContent<EpiContent>()
  const { deleteProfilePicture } = useUserInformation()
  const { openDialog } = useDialog()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    openDialog(DialogVariants.profilePicture)
    handleClose()
  }

  return (
    <>
      <Button variant="text" size="small" onClick={handleChangeButton}>
        Ändra
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{ dense: true }}
      >
        <MenuItem onClick={handleEdit}>{profilBild.editImageLabel}</MenuItem>
        <AddPictureButton
          menuItemButton
          menuItemLabel={profilBild.changeImageLabel}
        />
        <MenuItem onClick={deleteProfilePicture}>
          {profilBild.deleteImageLabel}
        </MenuItem>
      </Menu>
    </>
  )
}

interface AddPictureProps {
  menuItemButton?: boolean
  menuItemLabel?: string
}

const AddPictureButton = ({
  menuItemButton,
  menuItemLabel,
}: AddPictureProps) => {
  const hiddenFileInputRef = useRef<null | HTMLInputElement>(null)
  const { uploadUserProfilePicture } = useUserInformation()

  const handleAddButtonClick = () => {
    if (!hiddenFileInputRef.current) return

    hiddenFileInputRef.current.click()
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        hidden
        onChange={uploadUserProfilePicture}
        ref={hiddenFileInputRef}
      />
      {menuItemButton ? (
        <MenuItem onClick={handleAddButtonClick}>{menuItemLabel}</MenuItem>
      ) : (
        <Button variant="text" size="small" onClick={handleAddButtonClick}>
          Lägg till
        </Button>
      )}
    </>
  )
}

const Profilbild = () => {
  const { profilBild } = useEpiContent<EpiContent>()
  const theme = useTheme()
  const { hasProfilePicture } = useUserInformation()

  return (
    <ListGroup title={profilBild.heading}>
      <ListItem>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          minHeight="40px"
        >
          <Grid item alignSelf="center">
            <Typography variant="body1" color={theme.palette.text.secondary}>
              {profilBild.label}
            </Typography>
          </Grid>

          <Grid item zeroMinWidth>
            <ProfilePictureAvatar />
          </Grid>
          <Grid item>
            {hasProfilePicture ? <ChangePictureButton /> : <AddPictureButton />}
          </Grid>
        </Grid>
      </ListItem>
    </ListGroup>
  )
}

export default Profilbild
