import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import HTMLMapper from '@trr/html-mapper'
import { ConsentBlock } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useConsent } from 'Utils/ConsentContext'

export interface ConsentDialogContent {
  consent: ConsentBlock
  isActive: boolean
}

const ConsentDialog = () => {
  const { dialogType, closeDialog, content } = useDialog()
  const { updateConsents } = useConsent()

  const consentContent = (content as ConsentDialogContent) || {}

  const consentKey =
    consentContent?.consent?.properties.consent.consentKey ?? ''

  const defaults = {
    consent: {
      consentModalHeading: '',
      consentModalEditor: '',
      cancelConsentModalEditor: '',
      cancelConsentModalHeading: '',
    },
    modalAbortButton: '',
  }

  const {
    consent: {
      consentModalHeading,
      consentModalEditor,
      cancelConsentModalEditor,
      cancelConsentModalHeading,
    },
    modalAbortButton,
  } = consentContent?.consent?.properties ?? defaults

  const handleSave = () => {
    updateConsents(consentKey, !consentContent?.isActive)
    closeDialog()
  }

  const handleCancel = () => {
    closeDialog()
  }

  const onKeyUp = (evt: React.KeyboardEvent<HTMLDivElement>) =>
    evt.key === 'Enter' && handleSave()

  return (
    <>
      {dialogType === DialogVariants.consent && (
        <Dialog
          open={dialogType === DialogVariants.consent}
          onClose={closeDialog}
          onKeyUp={onKeyUp}
        >
          <DialogTitle>
            {(consentContent?.isActive && cancelConsentModalHeading) ||
              consentModalHeading}
          </DialogTitle>
          <DialogContent>
            <HTMLMapper
              body={
                (consentContent?.isActive && cancelConsentModalEditor) ||
                consentModalEditor
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="cancel-button"
              variant="text"
              onClick={handleCancel}
            >
              {modalAbortButton}
            </Button>
            <Button data-testid="ok-button" variant="text" onClick={handleSave}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ConsentDialog
