import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { EpiContent } from 'Types'
import { DialogVariants, useDialog } from 'Utils/DialogContext'
import { useUserInformation } from 'Utils/UserContext'
import React, { useCallback, useRef, useState } from 'react'
import AvatarEditor, { Position } from 'react-avatar-editor'

const ProfilePictureDialog = () => {
  const { dialogType, closeDialog } = useDialog()
  const { updateImageCropData, uncroppedProfilePictureUrl } =
    useUserInformation()
  const { profilBildDialog } = useEpiContent<EpiContent>()
  const [sliderValue, setSliderValue] = useState(1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-explicit-any
  const editorRef = useRef<any | null>(null)

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const changePosition = useCallback((position: Position) => {
    setPosition({ x: position.x, y: position.y })
  }, [])

  const handleSave = useCallback(() => {
    if (!isImageLoaded) return closeDialog()

    const croppingRect = editorRef.current.getCroppingRect()

    const image = new Image()
    let orginalWidth: number
    let orginalHeight: number

    image.onload = () => {
      orginalWidth = image.width
      orginalHeight = image.height
      const cropRatioX = orginalWidth * croppingRect.width
      const cropRatioY = orginalHeight * croppingRect.height
      const cropPosX = croppingRect.x >= 0 ? croppingRect.x : 0
      const cropPosY = croppingRect.y >= 0 ? croppingRect.y : 0
      const cropInformation = { cropRatioX, cropRatioY, cropPosX, cropPosY }

      updateImageCropData(JSON.stringify(cropInformation))
    }

    image.src = uncroppedProfilePictureUrl
    closeDialog()
  }, [
    editorRef,
    uncroppedProfilePictureUrl,
    updateImageCropData,
    closeDialog,
    isImageLoaded,
  ])

  const handleCancel = () => {
    closeDialog()
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number)
  }

  return (
    <Dialog
      open={dialogType === DialogVariants.profilePicture}
      sx={{ minWidth: '300px' }}
      onClose={closeDialog}
    >
      <DialogTitle>{profilBildDialog.heading}</DialogTitle>
      <DialogContent sx={{ maxWidth: '368px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 2,
          }}
        >
          {!isImageLoaded && (
            <Box
              sx={{
                width: 300,
                height: 300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <AvatarEditor
            image={uncroppedProfilePictureUrl}
            ref={editorRef}
            onImageReady={() => setIsImageLoaded(true)}
            borderRadius={150}
            border={50}
            style={{
              display: isImageLoaded ? 'block' : 'none',
              borderRadius: 50,
            }}
            color={[255, 255, 255]}
            scale={sliderValue}
            position={position}
            onPositionChange={changePosition}
          />
        </Box>
        <Slider
          disabled={!isImageLoaded}
          value={sliderValue}
          step={0.1}
          min={1}
          max={2}
          onChange={handleSliderChange}
        />
        <Typography variant="caption">{profilBildDialog.body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>
          Avbryt
        </Button>
        <Button
          variant="text"
          data-testid="profile-picture-savebtn"
          onClick={handleSave}
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProfilePictureDialog
